<template>
  <Scaffold>
    <template #buttons>
      <Button type="primary" @click="onCreate">추가</Button>
    </template>

    <div class="page-content">
      <CTable
          header-key="reviewList"
          v-bind="tableBinding()"
          @reload="getData"
          @page-change="pageChange"
          v-model="searchParams.search_keyword"
          @on-search="onSearch"
          search-placeholder="ID, 유저 이름, 휴대폰으로 검색"
      >
        <template #action="{row}">
          <div class="r-gap-buttons">
            <Button size="small" type="warning" @click="() => onEdit(row.id)">수정</Button>
            <Button size="small" type="error" @click="() => onDelete(row.id)">삭제</Button>
          </div>

        </template>
      </CTable>
    </div>
  </Scaffold>
</template>

<script>
import {tableMixin} from "@/mixins/table";

export default {
  mixins: [tableMixin],
  computed: {},
  data() {
    return {
      apiUrl: 'review'
    }
  },
  methods: {
    onCreate() {
      this.$router.push({name: 'review-editor', params: {id: 'new'}})
    },
    onEdit(id) {
      this.$router.push({name: 'review-editor', params: {id}})
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>